<template>
	<div v-if="canEditDescription">
		<v-tooltip v-if="simpleButtonDisplay && !listTileDisplay && !singleTileDisplay" bottom>
			<template v-slot:activator="{ on }">
				<v-btn icon @click="showEditDescriptionDialog = true" v-on="on">
					<v-icon color="primary" class="mr-1">mdi-form-textbox</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.edit_description'" />
		</v-tooltip>
		<v-list-tile v-else-if="listTileDisplay && !singleTileDisplay" avatar @click="showDialog()">
			<v-list-tile-avatar>
				<v-icon color="primary">mdi-form-textbox</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'documents.edit_description'" />
			</v-list-tile-content>
		</v-list-tile>
		<v-list-tile v-else-if="singleTileDisplay">
			<v-list-tile-content>
				<v-list-tile-title>{{ $t('description') }}</v-list-tile-title>
				<v-list-tile-sub-title>{{ node.description }}</v-list-tile-sub-title>
			</v-list-tile-content>
			<v-list-tile-action>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn icon @click="showDialog()" v-on="on">
							<v-icon color="grey lighten-1">edit</v-icon>
						</v-btn>
					</template>
					<span v-t="'documents.edit_description'" />
				</v-tooltip>
			</v-list-tile-action>
		</v-list-tile>
		<EditDescriptionDialog v-if="showEditDescriptionDialog" v-model="node" @close="closeDialog()" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

export default {
	name: 'EditDescriptionButton',
	components: {
		EditDescriptionDialog: () => ({
			component: import('@/components/Documents/Actions/Description/EditDescriptionDialog')
		})
	},
	mixins: [DocumentActionModuleGuard],
	props: {
		singleTileDisplay: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [Object, Array],
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			showEditDescriptionDialog: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		}),
		nodes: function () {
			return Array.isArray(this.value) ? this.value : [this.value] || []
		},
		node: function () {
			return this.nodes.length == 1 ? this.nodes[0] : null
		},
		canEditDescription: function () {
			return (
				this.node?.folder_id &&
				!this.isGuest &&
				!this.node?.is_deleted &&
				!this.node?.is_readonly &&
				!this.node?.is_trash &&
				(this.node?.is_folder || !this.node.is_default)
			)
		}
	},
	methods: {
		...mapActions([
			'setPauseKeyboardListening',
			'setPreventDefaultKeyboardEvents',
		]),
		showDialog: function () {
			if (!this.canEditDescription) {
				return
			}
			this.setPreventDefaultKeyboardEvents(false)
			this.setPauseKeyboardListening(true)
			this.showEditDescriptionDialog = true
		},
		closeDialog: function () {
			this.setPauseKeyboardListening(false)
			this.setPreventDefaultKeyboardEvents(true)
			this.showEditDescriptionDialog = false
		}
	}
}
</script>
